import { usePaywall } from "../../../services/hooks/handleRedirectToPaywall";
import { useTrack } from "../../../services/hooks/useTrack";
import { BBLock, Favorite2Icon, FavoriteDisabled2Icon } from "../../quarks/icons";
import { BreadCrumbStyle } from "./style";
import styled from "styled-components";


const FavoriteIconContainer = styled.div`
  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    filter: brightness(1.1);
  }
`;


export const BreadCrumbDrugsComponent = props =>{ 

    const {redirectToPaywall} = usePaywall()
    const {trackEvent} = useTrack()

    return (
        <BreadCrumbStyle {...props}>
            <h2 className="title_tg">
                {props.children}
            </h2>
            {props.isPaid && 
                <button 
                    className="paid__button" 
                    type="button" 
                    onClick={() => {
                        trackEvent(
                        "Clicou no cadeado",
                        {
                            'Origem': "Drogas",
                            'Grupo terapêutico': props.children,
                        }
                        );
                        redirectToPaywall()
                    }}
                >
                    <BBLock/>
                </button>
            }
            {props.isPaid ? null : props?.isFavorited ?
                <FavoriteIconContainer onClick={() => props?.removeFavorite({
                        title: props.children,
                        legend: "Grupo Terapêutico",
                        tgId: props?.tg_id,
                        type: "therapeutic_group"
                    })}
                >
                    <Favorite2Icon width="30" heigth="30"/>
                </FavoriteIconContainer>
                :
                <FavoriteIconContainer onClick={() => props?.addFavorite({
                        title: props.children,
                        legend: "Grupo Terapêutico",
                        tgId: props?.tg_id,
                        type: "therapeutic_group"
                    })}
                >
                    <FavoriteDisabled2Icon width="30" heigth="30"/>
                </FavoriteIconContainer>
            }
        </BreadCrumbStyle>
    )
};