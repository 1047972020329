import styled from 'styled-components';
import { borderRadius, colors, fontFamily, fontSize, spacing } from '../../../../blackbook-components/quarks/theme';

export const SearchResultCardStyle = styled.div`
   font-family: ${fontFamily.secondary};
   color: white;

   .search_result_card--header {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* background-color: #363636; */
      border-radius: 5px;

      h3 {
         display: grid;
         grid-template-columns: 20px auto auto;
         gap: 5px;
         place-items: center;
         /* display: flex; */
         align-items: center;
         padding: 0;
         margin: 0;
         font-family: ${fontFamily.primary};
         font-weight: bold;
         svg {
            /* margin-right: ${spacing.medium}; */
         }
         small {
            font-size: ${fontSize.small};
            font-weight: lighter;
            font-family: ${fontFamily.secondary};
            /* padding-left: ${spacing.medium}; */
         }
      }
   }

   .search_result_card--body {
      padding-top: 10px;
   }

   .showMore__container {
      height: 40px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 0 0 5px 5px;
      font-size: 14px;
      color: ${colors.primary};
      font-weight: bold;
      font-family: ${fontFamily.primary};
      cursor: pointer;
      z-index: 2;

   }
`

export const SearchResultCardItemStyle = styled.div`
   background-color: ${colors.black350};
   border-radius: ${borderRadius.small};
   margin-bottom: ${spacing.medium};
   overflow: visible;
   box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
   h4 , h5{
      margin: 0;
   }
   small {
      color: ${colors.gray};
   }

   &:last-child {
      margin-bottom: 0;
   }

   .search_result_card--item--header {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 5px 15px;
      box-sizing: border-box;
      min-height: 40px;

      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      z-index: 1;
      position: relative;

      .search_result_card--item--header--title {
         display: flex;
         flex-direction: column;
         gap: 2px;

         h4 {
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
         }

         small {
            font-size: 11px;
         }
      }

      .search_result_card--item--header--icon {
         width: 55px;
         font-size: ${fontSize.xxxlarge};
         position: relative;
         cursor: pointer;
         color: ${props => props.isOpen ? colors.secondary : colors.primary};
         display: flex;
         justify-content: flex-end;
         align-items: center;
         gap: 5px;


         .favorite__container {
            cursor: pointer;

            svg {
               height: 24px;
               position: relative;
               top: -11px;
               overflow: visible;

               transition: all 0.2s;

               &:hover {
                  filter: brightness(0.7);
               }

            }
         }
      }
   }
   .search_result_card--item--sub_item {
      display: ${props => props.isOpen ? 'block' : 'none'};
      background-color: ${colors.black325};
      cursor: pointer;
      transition: background-color 0.2s;

      .search_result_card--item--sub_item--title {
         padding: 5px 10px;
         border-bottom: 1px solid ${colors.darkGray};
         display: flex;
         flex-direction: column;
         justify-content: center;
         gap: 2px;
         min-height: 40px;
         box-sizing: border-box;
         h5 {
            font-size: 14px;
            line-height: 16px;
         }

         small {
            font-size: 11px;
         }
      }
      &:last-child {
         .search_result_card--item--sub_item--title {
            border-bottom: none;
         }
      }

      &:hover {
         background-color: #2f2f2f;
      }
   }
`
