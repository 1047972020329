import PropTypes from 'prop-types';
import styled from "styled-components";
import { ContentBannerStyle } from "./style";
import { BBLock, BbRoutineIcon, FavoriteDisabledIcon, FavoriteIcon } from '../../quarks/icons';
import { usePaywall } from '../../../services/hooks/handleRedirectToPaywall';
import { useTrack } from '../../../services/hooks/useTrack';

const FavoriteIconContainer = styled.div`
    cursor: pointer;
    transition: all 0.2s;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 10px;
    margin-top: -7px;

    &:hover {
        filter: brightness(1.1);
    }
`;

export const ContentBannerComponent = (props) => {
    const {redirectToPaywall} = usePaywall()
    const {trackEvent} = useTrack()

    return (
        <ContentBannerStyle>
            <div className="image">
                <img src={props?.image} alt={props?.title} />
            </div>
            <div className="title">
                {props.isBlocked && 
                    <button 
                        className="paid__button" 
                        type="button" 
                        onClick={() => {
                            trackEvent(
                            "Clicou no cadeado",
                            {
                                'Origem': "Rotinas",
                                'Rotina': `${props?.title} - ${props?.legend}`
                            }
                            );
                            redirectToPaywall()
                        }}
                        >
                        <BBLock/>
                    </button>
                }
                {props.isBlocked ? null : props?.isFavorited ?
                    <FavoriteIconContainer onClick={() => props?.removeFavorite({
                        title: props?.title,
                        legend: props?.legend,
                        routineId: props?.routineId,
                        type: "routine"
                    })}>
                        <FavoriteIcon/>
                    </FavoriteIconContainer>
                    :
                    <FavoriteIconContainer onClick={() => props?.addFavorite({
                        title: props?.title,
                        legend: props?.legend,
                        routineId: props?.routineId,
                        type: "routine"
                    })}>
                        <FavoriteDisabledIcon/>
                    </FavoriteIconContainer>
                }
                <h2>
                    <BbRoutineIcon width="18" heigth="18"/>
                    {`${props?.title} - ${props?.legend}`}
                </h2>
            </div>
            
        </ContentBannerStyle>
    )
}

ContentBannerComponent.propTypes = {
    title: PropTypes.string,
    legend: PropTypes.string,
    image: PropTypes.string,
}