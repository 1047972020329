import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CardComponent } from '../../../../blackbook-components/atoms/card';
import { SearchResultCardItemStyle, SearchResultCardStyle } from './style';
import {
  BBSearchChevron,
  BBVectorIcon,
  FavoriteDisabledIcon,
  FavoriteIcon,
} from '../../../../blackbook-components/quarks/icons';
import { colors } from '../../../../blackbook-components/quarks/theme';
import { useSidebar } from '../../../../context/useSidebar';
import { FavoriteModal } from '../../../../components/molecules/FavoriteModal';

export const SearchResultCardItem = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = (item) => props.onClick(item);

  const handleSubItemClick = (subItem, item) =>
    props.onSubItemClick(subItem, item);

  function handleGetColor(item) {
    const colorByType = {
      brand: '#2A7EC6',
      dosage: '#258138',
    };

    return item.color || colorByType[item?.type] || null;
  }

  function handleClickItem() {
    const hasSubItems = props.sub_items && props.sub_items.length > 0;

    if (hasSubItems) setIsOpen(!isOpen);

    props.onClick(props, hasSubItems);
  }

  const [isFav, setIsFav] = useState(props.isFavorited);

  useEffect(() => {
    setIsFav(props?.isFavorited);
  }, [props?.isFavorited]);

  return (
    <SearchResultCardItemStyle isOpen={isOpen}>
      <div
        className={`search_result_card--item--header --track-search-mark-${props.type}`}
        data-item-id={props.item_id}
        onClick={handleClickItem}
      >
        <div className="search_result_card--item--header--title">
          <h4>{props.title}</h4>
          <small>{props.legend}</small>
        </div>
        <div className="search_result_card--item--header--icon">
          {props.hasFavorites && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                props?.handleClickFavorite?.(props, props.isFavorited);
                setIsFav(!props.isFavorited);
              }}
              className="favorite__container"
            >
              {isFav || props.isFavorited ? (
                <FavoriteIcon />
              ) : (
                <FavoriteDisabledIcon />
              )}
            </div>
          )}
          {props.sub_items && props.sub_items.length > 0 ? (
            <BBSearchChevron
              isUpDirection={!isOpen}
              color={isOpen ? colors.secondary : colors.primary}
              width={16}
            />
          ) : (
            <BBVectorIcon />
          )}
        </div>
      </div>
      {props.sub_items && props.sub_items.length > 0 && (
        <div className="search_result_card--item--sub_items">
          {props.sub_items.map((sub_item) => (
            <div
              className={`search_result_card--item--sub_item --track-search-mark-${props.type}`}
              data-item-id={`${props.legend_id}${props.item_id}${sub_item.sub_item_id}`}
              style={{ borderLeft: `solid 10px ${handleGetColor(sub_item)}` }}
              onClick={() => handleSubItemClick(sub_item, props)}
            >
              <div className="search_result_card--item--sub_item--title">
                <h5>{sub_item.title}</h5>
                <small style={{ color: handleGetColor(sub_item) }}>
                  {sub_item.legend}
                </small>
              </div>
            </div>
          ))}
        </div>
      )}
    </SearchResultCardItemStyle>
  );
};

export const SearchResultCardComponent = ({
  title,
  icon,
  results,
  onItemClick,
  onSubItemClick,
  onShowMoreClick,
  style,
  hasFavorites,
  type,
}) => {
  const { myBB, addFavoriteByType, removeFavoriteByType } = useSidebar();
  const [isResultsLimited, setIsResultsLimited] = useState(true);

  function handleCheckFav(data) {
    if (myBB?.[type]?.length > 0) {
      return !!myBB[type].find((e) => e.item_id === data.item_id);
    }
    return false;
  }

  const [itemToRemove, setItemToRemove] = useState(null);

  async function handleClickFavorite(item, isFavorited) {
    if (isFavorited) {
      return setItemToRemove(item);
    }
    if (item.sub_type === 'active_principle') {
      return addFavoriteByType(
        'active_principles',
        item.legend_id,
        item.item_id
      );
    }
    if (item.sub_type === 'therapeutic_group') {
      return addFavoriteByType('therapeutic_group', item.item_id);
    }

    if (item.sub_type === 'routine') {
      return addFavoriteByType('routine', item?.item_id, undefined);
    }
  }

  async function removeFavorite(item) {
    if (item.sub_type === 'active_principle') {
      await removeFavoriteByType(
        'active_principles',
        item.item_id,
        item.legend_id
      );
    }
    if (item.sub_type === 'therapeutic_group') {
      await removeFavoriteByType('therapeutic_group', item.item_id);
    }
    if (item?.type === 'routine') {
      await removeFavoriteByType('routine', item?.item_id, undefined);
    }
    setItemToRemove(null);
  }

  return (
    // <CardComponent padding="0" className="search_result_card">
    <SearchResultCardStyle
      style={style}
      isLimited={isResultsLimited}
      itHasNoResults={results.length === 0}
    >
      {itemToRemove && (
        <FavoriteModal
          cancelOnPress={() => setItemToRemove(null)}
          successOnPress={() => removeFavorite(itemToRemove)}
          title="REMOVER DO MEU BLACKBOOK ?"
          titleContent={itemToRemove?.title}
          legendContent={itemToRemove?.legend}
          isLinkedContentAlert={itemToRemove?.type === 'routine'}
          isLoadingButton={false}
        />
      )}
      <div className="search_result_card--header">
        <h3>
          {icon}
          {title.toUpperCase()} <small>({results.length} resultados)</small>
        </h3>
      </div>
      {results.data.length > 0 && (
        <>
          <div className="search_result_card--body">
            {results.data
              .slice(0, isResultsLimited ? 3 : results.data.length)
              .map((result) => (
                <SearchResultCardItem
                  handleClickFavorite={handleClickFavorite}
                  isFavorited={handleCheckFav(result)}
                  hasFavorites={hasFavorites}
                  key={result.id}
                  {...result}
                  onClick={onItemClick}
                  onSubItemClick={onSubItemClick}
                />
              ))}
          </div>
          {results.data.length > 3 && (
            <div
              className="showMore__container"
              onClick={() => {
                onShowMoreClick(isResultsLimited);
                setIsResultsLimited(!isResultsLimited);
              }}
            >
              <span
                style={{
                  display: isResultsLimited ? 'block' : 'none',
                  zIndex: 1,
                }}
              >
                VER MAIS EM {title.toUpperCase()}
              </span>
              <span
                style={{
                  display: !isResultsLimited ? 'block' : 'none',
                  zIndex: 1,
                }}
              >
                VER MENOS
              </span>
            </div>
          )}
        </>
      )}
    </SearchResultCardStyle>
    // </CardComponent>
  );
};

SearchResultCardComponent.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      legend: PropTypes.string.isRequired,
      legend_id: PropTypes.string.isRequired,
      legend_type: PropTypes.string.isRequired,
      free_access: PropTypes.bool.isRequired,
      sub_items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          section: PropTypes.string.isRequired,
          color: PropTypes.string,
          free_access: PropTypes.bool.isRequired,
        })
      ),
    })
  ),
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onSeeMoreClick: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
  onSubItemClick: PropTypes.func.isRequired,
};
