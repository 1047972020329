import { useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ContentRoutineSidebar } from '../../blackbook-components/molecules/content-routineSidebar';
import { PopupContentComponent } from '../../blackbook-components/molecules/popup-content';
import { RoutineDetailComponent } from '../../blackbook-components/organisms/routine-detail';
import { BBLoadingGif } from '../../blackbook-components/quarks/blackbook-loading';
import { AccordionTable } from '../../components/atoms/AccordionTable';
import { WebAppTemplate } from '../../components/templates/WebAppTemplate';
import { usePopup } from '../../context/usePopup';
import { useTrack } from '../../services/hooks/useTrack';
import { GET_ROUTINE } from './query';
import { ContentWrapper } from './style';
import _ from 'lodash';
import { useMyBlackbook } from '../../services/hooks/useMyBlackbook';
import { FavoriteModal } from '../../components/molecules/FavoriteModal';

export function WebAppRoutines() {
  const { routine_id, tab_id, card_id } = useParams();
  const { trackEvent } = useTrack();
  const routineContentRef = useRef();
  const location = useLocation();
  const [hightlightedItem, setHightlightedItem] = useState({
    tab: null,
    card: null,
  });
  const {getFavoritesByType, addFavorite, removeFavorite} = useMyBlackbook();
  const [favorites, setFavorites] = useState(undefined);
  const [itemToRemove, setItemToRemove] = useState(null);

  async function handleFavorites() {
    const {routine_cards} = await getFavoritesByType("routine_cards", routine_id);
    setFavorites(routine_cards);
  }

  async function removeFavoriteByType(item) {
    if (item?.type === 'routine') {
      const isRemove = await removeFavorite('routine', item?.routineId, undefined);
      if(isRemove) {
        setFavorites(null);
        toast.success('Removido do MEU BLACKBOOK');
      }
      setItemToRemove(null);
      return;
    }
    
    if (item?.type === 'card') {
      const isRemove = await removeFavorite('routine_cards', item?.cardId, item?.routineId);
      if(isRemove) {
        setFavorites({
          ...favorites,
          cards: favorites?.cards.filter(c => c !== item?.cardId)
        });
        toast.success('Removido do MEU BLACKBOOK');
      }
      setItemToRemove(null);
      return;
    }
  }

  async function addFavoriteByType(item) {
    if (item?.type === 'routine') {
      const isAdd = await addFavorite('routine', item?.routineId, undefined);
      
      if(isAdd) {
        setFavorites({
          cards: [],
          routineId: item?.routineId
        });
        toast.success('Adicionado ao MEU BLACKBOOK');
      }
      return;
    }
    
    if (item?.type === 'card') {
      const isAdd = await addFavorite('routine_cards', item?.routineId, item?.cardId);
      
      if(isAdd) {
        setFavorites({
          routineId: item?.routineId,
          cards: [...favorites?.cards || [], item?.cardId]
        });
        toast.success('Adicionado ao MEU BLACKBOOK');
      }
      return;
    }
  }

  useEffect(() => {
    handleFavorites();
    return setFavorites(undefined);
  }, [routine_id]);

  function handleScrollSidebarItems(id) {
    const element = document?.getElementById(`sidebar-${id}`);
    element?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  }

  function handleKeyboardArrowUp() {
    const activeCard = document?.querySelector('li.sub_item_menu.active');

    if (!activeCard) return;

    const sibling = activeCard?.previousElementSibling;
    if (sibling) {
      const parent = sibling.closest('li.contentSidebar');

      scrollRoutineElementIntoView(
        parent?.id?.replace('sidebar-', ''),
        sibling?.id?.replace('sidebar-', ''),
        routineContentRef.current,
        'instant'
      );
    } else {
      const parent =
        activeCard.closest('li.contentSidebar').previousElementSibling;
      const lastCard = Array.from(
        parent.querySelectorAll('li.sub_item_menu')
      )?.at(-1)?.id;

      scrollRoutineElementIntoView(
        parent?.id?.replace('sidebar-', ''),
        lastCard?.replace('sidebar-', ''),
        routineContentRef.current,
        'instant'
      );
    }
  }
  function handleKeyboardArrowDown() {
    const activeCard = document?.querySelector('li.sub_item_menu.active');

    if (!activeCard) {
      const fistCard = document?.querySelector('.routine__card');
      const parent = fistCard?.parentElement;

      scrollRoutineElementIntoView(
        parent?.id?.replace('sidebar-', ''),
        fistCard?.id?.replace('sidebar-', ''),
        routineContentRef.current,
        'instant'
      );

      return;
    }

    const card = document?.getElementById(
      activeCard?.id.replace('sidebar-', '')
    );

    const sibling = card?.nextElementSibling;
    if (sibling) {
      const parent = sibling.parentElement;

      scrollRoutineElementIntoView(
        parent?.id?.replace('sidebar-', ''),
        sibling?.id?.replace('sidebar-', ''),
        routineContentRef.current,
        'instant'
      );
    } else {
      const parent = card.parentElement.nextElementSibling;

      const fistCard = parent.querySelector('.routine__card ')?.id;

      scrollRoutineElementIntoView(
        parent?.id?.replace('sidebar-', ''),
        fistCard?.replace('sidebar-', ''),
        routineContentRef.current,
        'instant'
      );
    }
  }

  function handleKeyboardKey(key) {
    if (key === 'End') {
      const lastCard = Array.from(
        document.querySelectorAll('.routine__card:last-of-type ')
      )?.at(-1);
      const parent = lastCard.parentElement;

      scrollRoutineElementIntoView(
        parent?.id,
        lastCard?.id,
        routineContentRef.current
      );
    } else if (key === 'Home') {
      const fistCard = document.querySelector('.routine__card ');
      const parent = fistCard.parentElement;

      scrollRoutineElementIntoView(
        parent?.id,
        fistCard?.id,
        routineContentRef.current
      );
    }
  }

  useEffect(() => {
    handleScrollSidebarItems(hightlightedItem?.card || hightlightedItem?.tab);
  }, [hightlightedItem]);

  useEffect(() => {
    if (routineContentRef && routineContentRef.current) {
      document?.addEventListener('keydown', (e) => {
        if (e.key === 'End' || e.key === 'Home') {
          e.preventDefault();
          handleKeyboardKey(e.key);
        }

        if (e.key === 'ArrowUp' || e.key === 'PageUp') {
          e.preventDefault();
          if (e.repeat) {
            e.preventDefault();
            return false;
          }

          e.stopImmediatePropagation();
          handleKeyboardArrowUp();
          return false;
        }
        if (e.key === 'ArrowDown' || e.key === 'PageDown') {
          e.preventDefault();

          if (e.repeat) {
            e.preventDefault();
            return false;
          }

          handleKeyboardArrowDown();

          return false;
        }
      });
    }

    return document?.removeEventListener('keydown', (e) => {
      if (e.key === 'ArrowUp' || e.key === 'PageUp') {
        e.preventDefault();

        handleKeyboardArrowUp();
      }
      if (e.key === 'ArrowDown' || e.key === 'PageDown') {
        e.preventDefault();

        handleKeyboardArrowDown();
      }
    });
  });

  function scrollRoutineElementIntoView(
    tab,
    card,
    elementRef,
    behavior = 'smooth'
  ) {
    const tabElement =
      document?.getElementById(tab) ||
      document?.getElementById(card)?.parentElement;

    const tabT = tabElement.offsetTop;

    const cardT = !!card ? document?.getElementById(card)?.offsetTop : 0;
    const breadCrumb = tabElement?.querySelector('.breadCrumb__container');
    const breadCrumbHeight = !!breadCrumb ? breadCrumb.offsetHeight : 0;

    if (tabT) {
      elementRef?.scrollTo({
        top: tabT + cardT - breadCrumbHeight - 50,
        behavior: behavior,
      });
    }
  }

  const [routine, setRoutine] = useState(null);

  const { loading, error, data } = useQuery(GET_ROUTINE, {
    variables: { routineId: routine_id },

    // fetchPolicy: 'no-cache',
    onCompleted: (routine) => {
      if (location?.state?.trackOrigin === 'Banner') {
        trackEvent('Clicou no banner', {
          Destino: `${routine.routine_populated.title} - ${routine.routine_populated.legend}`,
          Origem: 'Banner',
          'Tipo da origem': 'Banner',
          'Tipo do destino': 'Rotinas',
          'Link id': location?.state?.trackLink,
        });
      }
      trackEvent('Acessou Rotinas', {
        'Tipo da origem': location?.state?.trackType,
        Origem: location?.state?.trackOrigin,
        Rotina: `${routine?.routine_populated.title} - ${routine?.routine_populated.legend}`,
        Resolução: `(${window.screen?.width * window.devicePixelRatio} X ${
          window.screen?.height * window.devicePixelRatio
        })`,
      });



      const temp = _.cloneDeep(routine);
      const a = temp?.routine_populated
        ? {
            _id: temp.routine_populated._id,
            title: temp.routine_populated.title,
            legend: temp.routine_populated.legend,
            image: temp.routine_populated.image,
            tabs: temp.routine_populated.tabs,
            cards: temp.routine_populated.tabs.reduce(
              (total, tab) => total.concat(tab.cards),
              []
            ),
            isBlocked: temp.routine_populated.is_blocked,
          }
        : null;
      setRoutine(a);
    },
  });

  // const temp = _.cloneDeep(data)
  // const routine = temp?.routine_populated
  //   ? {
  //       title: temp.routine_populated.title,
  //       legend: temp.routine_populated.legend,
  //       image: temp.routine_populated.image,
  //       tabs: temp.routine_populated.tabs,
  //       cards: temp.routine_populated.tabs.reduce(
  //         (total, tab) => total.concat(tab.cards),
  //         []
  //       ),
  //       isBlocked: temp.routine_populated.is_blocked,
  //     }
  //   : null;

  const { popup, setPopup } = usePopup();


  const [locationKey, setLocationKey] = useState(null);


  useEffect(() => {
    if (locationKey !== null && locationKey !== location.key) {
        handleScrollByKeyOrURL();
        setLocationKey(location.key);
    } else {
      setLocationKey(location.key);
    }

    // return () => {
      // handleRemoveListener()
    // };
  }, [location.key]);

  function handleScrollByKeyOrURL() {
    let urlString = location.key;
    let a = sessionStorage.getItem(urlString);

    if (a) {
      routineContentRef.current.scrollTop = a;
    } else {
      setTimeout(() => {
        scrollRoutineElementIntoView(tab_id, card_id, routineContentRef.current);
      }, 500);
    }
  }

  // function handleRemoveListener() {
  //   let urlString = location.key;

  //     // console.log("SALVA ESTAS INFORMAçoes em: ", urlString, routineContentRef.current.scrollTop)

  //     console.log("Effect key -------", urlString, routineContentRef.current)
  //     sessionStorage.setItem(urlString, routineContentRef?.current?.scrollTop);
  // }

  return (
    <WebAppTemplate>
      {popup !== null && (
        <PopupContentComponent isOpen={popup != null} popupTable={true}>
          <AccordionTable
            extra={popup}
            popup={true}
            onClose={() => setPopup(null)}
          />
        </PopupContentComponent>
      )}
      <ContentWrapper>
        {loading || error || favorites === undefined ? (
          <BBLoadingGif />
        ) : (
          <>
            <ContentRoutineSidebar
              routine={routine}
              favorites={favorites}
              addFavorite={addFavoriteByType}
              removeFavorite={setItemToRemove}
              items={routine?.tabs}
              className="contentSidebar light"
              onChildClick={(card, tab) => {
                scrollRoutineElementIntoView(
                  tab._id,
                  card._id,
                  routineContentRef.current
                );
              }}
              hightlightedItem={hightlightedItem}
            />
            <RoutineDetailComponent
              routineContentRef={routineContentRef}
              scrollElementIntoView={scrollRoutineElementIntoView}
              setHightlightedItem={setHightlightedItem}
              hightlightedItem={hightlightedItem}
              handleScrollByKeyOrURL={handleScrollByKeyOrURL}
              favorites={favorites}
              addFavorite={addFavoriteByType}
              removeFavorite={setItemToRemove}
              {...routine}
            />
          </>
        )}
      </ContentWrapper>
      {
        itemToRemove &&
        <FavoriteModal 
          cancelOnPress={() => setItemToRemove(null)}
          successOnPress={() => removeFavoriteByType(itemToRemove)}
          title='REMOVER DO MEU BLACKBOOK ?'
          titleContent={itemToRemove?.title}
          legendContent={itemToRemove?.legend}
          isLinkedContentAlert={itemToRemove?.type === 'routine'}
          isLoadingButton={false}
        />
      }
    </WebAppTemplate>
  );
}
