/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { WebAppTemplate } from '../../components/templates/WebAppTemplate';
import MainTheme from '../../styles/MainTheme';
import {
  BbCardIcon,
  BbkIcon,
  BbRoutineIcon,
  FavoriteDisabled2Icon,
  FavoriteIcon,
} from '../../blackbook-components/quarks/icons';
import { ButtonComponent } from '../../components/atoms/buttonComponent';
import { useState } from 'react';
import { MyBlackbookCard } from '../../blackbook-components/molecules/my-blackbook-cards';
import { MyBlackbookSession } from '../../components/organisms/MyBlackbookSessions';
import { Modal } from '../../components/atoms/Modal';
import { useMyBlackbook } from '../../services/hooks/useMyBlackbook';
import { FavoriteModal } from '../../components/molecules/FavoriteModal';
import { EmptySearchImage } from '../../assets/icons/EmptySearchImage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 10px 10px;
  padding: 15px;
  padding-right: 0px;
  max-height: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .myblackbook__modal {
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      max-height: 600px;
      max-width: 500px;
      min-width: 300px;
      min-height: 400px;
      border-radius: 20px;
      padding: 10px;
    }

    .close-modal__button {
      background-color: #121212;
      border: none;
      margin-right: 10px;
    }
  }

  .emptyMyBB__container {
    max-width: 360px;
    margin: 50px auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.5;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: inherit;
    gap: 15px;
    svg {
      height: 150px;
      width: auto;
    }

    h3 {
      color: #ccd2d6;
      /* font-weight: bold; */
      text-align: center;
    }
  }
`;

const PageHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 15px;
  margin-right: 15px;
  border-top: 1.5px solid ${MainTheme.color.disabledFont};
  border-bottom: 1.5px solid ${MainTheme.color.disabledFont};
`;

const PageHeaderTitleContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
`;

const PageHeaderTitle = styled.text`
  color: ${MainTheme.color.primaryFont};
  font-weight: bold;
  font-size: 24px;
  text-align: left;
`;

const PageHeaderFilterContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-right: -10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 200px;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 580px;
  padding: 10px;
`;

const ModalHeaderTitle = styled.text`
  color: ${MainTheme.color.primaryFont};
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin-bottom: 10px;
  padding: 0px 5px;
`;

const ModalContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  max-width: 100%;
  padding: 5px;
  padding-bottom: 50px;
  overflow-y: auto;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const ModalCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${MainTheme.color.disabledBackground};
  border-radius: 10px;
  height: auto;
  gap: 10px;
  padding: 8px;

  border: 1px solid ${MainTheme.color.disabledBackground};
  position: relative;
  transition: all 0.2s;
  box-sizing: border-box;
  cursor: pointer;

  :hover {
    border: 1px solid ${MainTheme.color.primary};
    filter: brightness(0.9);
  }
`;

const FavoriteIconContainer = styled.div`
  position: absolute;
  top: -6px;
  right: 10px;
  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    filter: brightness(1.5);
  }
`;

const ContentBodyCardTitle = styled.text`
  color: ${MainTheme.color.primaryFont};
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  padding-right: 50px;

  svg {
    margin-right: 5px;
    margin-bottom: -2px;
  }
`;

const ContentBodyCardDescription = styled.text`
  color: ${MainTheme.color.grayFont};
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  margin-bottom: 5px;
`;

export function WebAppMyBlackbook() {
  const { getMyBlackbook, removeFavorite } = useMyBlackbook();
  const [activeFilter, setActiveFilter] = useState('todos');
  const [routineSubItens, setRoutineSubItens] = useState(null);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [myBlackbook, setMyBlackbook] = useState(null);

  const history = useHistory();

  async function handleFilter(type) {
    setActiveFilter(type);
  }

  async function handleSeeAll(type) {
    if (type === 'drugs') {
      handleFilter('drogas');
      return;
    }
    if (type === 'routines') {
      handleFilter('rotinas');
      return;
    }
  }

  async function removeFavoriteByType(item) {
    if (item?.sub_type === 'routine') {
      const isRemove = await removeFavorite(
        'routine',
        item?.item_id,
        undefined
      );
      if (isRemove) {
        const filtered = myBlackbook.routines.filter(
          (i) => i?.item_id !== item?.item_id
        );
        const newMybb = { ...myBlackbook, routines: filtered };
        const totalResults = favoriteCount(newMybb);
        setMyBlackbook({ ...newMybb, results: totalResults });
      }
      setItemToRemove(null);
      toast.success('Removido do MEU BLACKBOOK');
      return;
    }

    if (item?.sub_type === 'therapeutic_group') {
      const isRemove = await removeFavorite(
        'therapeutic_group',
        item?.item_id,
        undefined
      );
      if (isRemove) {
        const filtered = myBlackbook.drugs.filter(
          (i) => i?.item_id !== item?.item_id
        );
        const newMybb = { ...myBlackbook, drugs: filtered };
        const totalResults = favoriteCount(newMybb);
        setMyBlackbook({ ...newMybb, results: totalResults });
      }
      setItemToRemove(null);
      toast.success('Removido do MEU BLACKBOOK');
      return;
    }

    if (item?.sub_type === 'active_principle') {
      const isRemove = await removeFavorite(
        'active_principles',
        item?.item_id,
        item?.legend_id
      );
      if (isRemove) {
        const filtered = myBlackbook.drugs.filter(
          (i) => i?.item_id !== item?.item_id
        );
        const newMybb = { ...myBlackbook, drugs: filtered };
        const totalResults = favoriteCount(newMybb);
        setMyBlackbook({ ...newMybb, results: totalResults });
      }
      setItemToRemove(null);
      toast.success('Removido do MEU BLACKBOOK');
      return;
    }

    if (item?.type === 'card') {
      const isRemove = await removeFavorite(
        'routine_cards',
        item?.sub_item_id,
        routineSubItens?.item_id
      );
      if (isRemove) {
        const filtered = routineSubItens?.sub_items?.filter(
          (i) => i?.sub_item_id !== item?.sub_item_id
        );
        setRoutineSubItens({ ...routineSubItens, sub_items: filtered });
        const transform = myBlackbook.routines.map((i) => {
          if (i?.item_id === routineSubItens?.item_id) {
            return { ...routineSubItens, sub_items: filtered };
          }
          return i;
        });
        const newMybb = { ...myBlackbook, routines: transform };
        const totalResults = favoriteCount(newMybb);
        setMyBlackbook({ ...newMybb, results: totalResults });
      }
      setItemToRemove(null);
      toast.success('Removido do MEU BLACKBOOK');
      return;
    }
  }

  function favoriteSorter(data) {
    const RoutinesForSort = [...data.routines];
    const DrugsForSort = [...data.drugs];

    const drugs = DrugsForSort?.sort((a, b) => {
      if (new Date(a?.created_at) > new Date(b?.created_at)) {
        return -1;
      }
      return 1;
    });

    const routines = RoutinesForSort?.sort((a, b) => {
      if (new Date(a?.created_at) > new Date(b?.created_at)) {
        return -1;
      }
      return 1;
    });

    return {
      drugs,
      routines,
    };
  }

  function favoriteCount(data) {
    let drugsCount = data?.drugs?.length || 0;
    let routineCount = 0;

    data?.routines?.forEach((item) => {
      routineCount += 1 + (item?.sub_items?.length || 0);
    });

    return {
      drugs: drugsCount,
      routines: routineCount,
    };
  }

  async function handleMyBlackbook() {
    const result = await getMyBlackbook();
    if (result) {
      const sorter = favoriteSorter(result);
      const totalResults = favoriteCount(result);
      setMyBlackbook({ ...sorter, results: totalResults });
    }
  }

  function handleRedirectByClick(type, clickedItem, parent = null) {
    const selector = {
      routine: () => {
        const redirectType = {
          routine: () => {
            const routineId = clickedItem.item_id;

            history.push(`/app/routines/${routineId}`, {
              trackOrigin: 'Meu Blackbook',
              trackType: 'Meu Blackbook',
            });
          },
          card: () => {
            const routineId = parent.item_id;
            const tabId = clickedItem.legend_id;
            const cardId = clickedItem.sub_item_id;

            history.push(`/app/routines/${routineId}/${tabId}/${cardId}`, {
              trackOrigin: 'Meu Blackbook',
              trackType: 'Meu Blackbook',
            });
          },
        };
        return (
          redirectType[clickedItem?.sub_type]?.() ||
          redirectType[clickedItem?.type]?.() ||
          null
        );
      },
      drug: () => {
        const redirectType = {
          therapeutic_group: () => {
            const therapeuticGroupId = clickedItem.item_id;

            history.push(`/app/drugs/${therapeuticGroupId}`, {
              trackOrigin: 'Meu Blackbook',
              trackType: 'Meu Blackbook',
            });
          },
          active_principle: () => {
            const therapeuticGroupId = clickedItem.legend_id;
            const activePrincipleId = clickedItem.item_id;

            history.push(
              `/app/drugs/${therapeuticGroupId}/${activePrincipleId}`,
              {
                trackOrigin: 'Meu Blackbook',
                trackType: 'Meu Blackbook',
              }
            );
          },
        };
        return (
          redirectType[clickedItem?.sub_type]?.() ||
          redirectType[clickedItem?.type]?.() ||
          null
        );
      },
    };

    selector[type]();
  }

  function getCategoryCardByType(type) {
    const categoryMap = {
      active_principle: 'Princípio Ativo',
      therapeutic_group: 'Grupo Terapêutico',
      routine: 'Rotina',
    };

    return categoryMap[type] || '';
  }

  useEffect(() => {
    handleMyBlackbook();
  }, []);

  console.log(myBlackbook);

  return (
    <WebAppTemplate>
      <Container>
        <PageHeader>
          <PageHeaderTitleContainer>
            <BbkIcon />
            <PageHeaderTitle>Meu Blackbook</PageHeaderTitle>
          </PageHeaderTitleContainer>
          <PageHeaderFilterContainer>
            <ButtonComponent
              buttonProp={{
                type: 'button',
                className: `filter__button`,
                active: activeFilter === 'todos',
                onClick: () => handleFilter('todos'),
              }}
              children="TODOS"
            />
            <ButtonComponent
              buttonProp={{
                type: 'button',
                className: 'filter__button',
                active: activeFilter === 'rotinas',
                onClick: () => handleFilter('rotinas'),
              }}
              children="ROTINAS"
            />
            <ButtonComponent
              buttonProp={{
                type: 'button',
                className: 'filter__button',
                active: activeFilter === 'drogas',
                onClick: () => handleFilter('drogas'),
              }}
              children="DROGAS"
            />
          </PageHeaderFilterContainer>
        </PageHeader>
        {!!myBlackbook ? (
          <PageContentContainer>
            <MyBlackbookSession
              type="drugs"
              title={'DROGAS'}
              qtdFavorites={myBlackbook.results.drugs}
              data={myBlackbook.drugs.map((drug) => (
                <MyBlackbookCard
                  type={drug?.sub_type}
                  category={getCategoryCardByType(drug?.sub_type)}
                  title={drug.title}
                  description={`Drogas / ${drug?.legend}`}
                  handleRedirect={() => handleRedirectByClick(drug?.type, drug)}
                  icons={drug?.icons || []}
                  handleFavoriteClick={() => setItemToRemove(drug)}
                />
              ))}
              handleSeeAll={handleSeeAll}
              isFilteredMode={activeFilter === 'drogas'}
              isVisible={activeFilter === 'drogas' || activeFilter === 'todos'}
            />
            <MyBlackbookSession
              type="routines"
              title={'ROTINAS'}
              qtdFavorites={myBlackbook.results.routines}
              data={myBlackbook.routines.map((routine) => (
                <MyBlackbookCard
                  type={routine?.sub_type}
                  title={routine?.title}
                  category={routine?.legend}
                  description={`Rotinas`}
                  subItems={routine?.sub_items}
                  handleRedirect={() =>
                    routine?.sub_items?.length
                      ? setRoutineSubItens(routine)
                      : handleRedirectByClick(routine?.type, routine)
                  }
                  handleSeeAll={() => setRoutineSubItens(routine)}
                  handleFavoriteClick={() => setItemToRemove(routine)}
                />
              ))}
              handleSeeAll={handleSeeAll}
              isFilteredMode={activeFilter === 'rotinas'}
              isVisible={activeFilter === 'rotinas' || activeFilter === 'todos'}
            />
          </PageContentContainer>
        ) : (
          <div className="emptyMyBB__container">
            <h3>Aqui ficam os seus itens preferidos.</h3>
            <h3>
              Para adicionar itens ao Meu Blackbook, basta tocar no ícone
              correspondente disponível ao longo de todo o conteúdo.
            </h3>
            <FavoriteDisabled2Icon />
          </div>
        )}
        {routineSubItens && (
          <Modal
            key={routineSubItens?.item_id}
            onClose={() => setRoutineSubItens(null)}
            className="myblackbook__modal"
          >
            <ModalContainer>
              <ModalHeaderTitle>
                <BbRoutineIcon width="18" heigth="18" />{' '}
                {`${routineSubItens?.title} - ${routineSubItens.legend}`}
              </ModalHeaderTitle>
              <ModalContainerCard>
                {routineSubItens?.sub_items.map((item) => (
                  <ModalCard
                    key={item?.sub_item_id}
                    onClick={() =>
                      handleRedirectByClick(
                        routineSubItens?.type,
                        item,
                        routineSubItens
                      )
                    }
                  >
                    <FavoriteIconContainer
                      onClick={(e) => {
                        e.stopPropagation();
                        setItemToRemove(item);
                      }}
                    >
                      <FavoriteIcon width="28" heigth="28" />
                    </FavoriteIconContainer>
                    <ContentBodyCardTitle
                      style={{
                        color: item?.color,
                      }}
                      title={item?.legend}
                    >
                      <BbCardIcon color={item?.color} />
                      {item?.legend}
                    </ContentBodyCardTitle>
                    <ContentBodyCardDescription title={item?.title}>
                      {item?.title}
                    </ContentBodyCardDescription>
                  </ModalCard>
                ))}
              </ModalContainerCard>
            </ModalContainer>
          </Modal>
        )}
      </Container>
      {itemToRemove && (
        <FavoriteModal
          cancelOnPress={() => setItemToRemove(null)}
          successOnPress={() => removeFavoriteByType(itemToRemove)}
          title="REMOVER DO MEU BLACKBOOK ?"
          titleContent={itemToRemove?.title}
          legendContent={itemToRemove?.legend}
          isLinkedContentAlert={itemToRemove?.sub_type === 'routine'}
          isLoadingButton={false}
        />
      )}
    </WebAppTemplate>
  );
}
