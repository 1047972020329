import { createContext, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { gql, useLazyQuery } from "@apollo/client";
import {
  BbAvatarIcon,
  BbChatIcon,
  BbDrugIcon,
  BbExitIcon,
  BbHomeIcon,
  BbRoutineIcon,
  BbSmartphoneIcon,
  MyBbkIcon
} from "../blackbook-components/quarks/icons";
import { useUser } from "./useUser";
import { handleRemoveIndicationCouponOfLocalStorage } from "../services/cache/indicationCoupon";
import { getUserToken } from "../services/tokenService";
import { useTrack } from "../services/hooks/useTrack";
import { Calculator } from "../assets/icons/Calculator";
import calcList from "./utils/calcListForDisplay.json";
import cidJson from "./utils/CIDJson/capitulos.json";
import { CID } from "../assets/icons/CID";
import { useMyBlackbook } from "../services/hooks/useMyBlackbook";
import { toast } from "react-toastify";

const SidebarSpecialtiesContext = createContext();

const GET_SPECIALTIES = gql`
  query getSpecialties {
    sidebar_specialties {
      routine_specialties {
        _id
        title
        routines {
          _id
          title
          legend
          is_blocked
        }
      }
      drug_specialties {
        title
        _id
        therapeutic_groups {
          is_blocked
          title
          _id
        }
      }
    }
  }
`;

export const GET_SEARCH_SUGGESTIONS = gql`
  query SearchSuggestions {
    searchSuggestions {
      _id
      rank_points
      title
    }
  }
`;

export const SidebarSpecialtiesProvider = ({ children }) => {
  const history = useHistory();

  const {getMyBlackbook, removeFavorite, addFavorite} = useMyBlackbook()
  const { handleClearUserData } = useUser();
  const { trackEvent } = useTrack();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [drugsSpecialties, setDrugsSpecialties] = useState([]);
  const [routineSpecialties, setRoutineSpecialties] = useState([]);
  const [calculatorSpecialties, setCalculatorSpecialties] = useState([]);
  const [cidChapters, setCidChapters] = useState([]);

  const [subSidebarItems, setSubSidebarItems] = useState([]);

  const [isSubsideBarOpen, setisSubsideBarOpen] = useState([false]);
  const [hightlightedItem, setHightlightedItem] = useState([]);

  function parseCid() {
    let temp = [];
    cidJson?.forEach((item) => {
      const itemTemp = {
        key: `${item.NUMCAP}`,
        text: `${item.DESCRICAO}`,
        isActive: false,
        onClick: () => {
          trackEvent("CID - Clicou no capítulo", {
            Capítulo: `${item.CATINIC} - ${item.CATFIM} ${item.DESCRICAO}`,
          });
          history.push(`/app/CID/${item.NUMCAP}`);
        },
      };
      temp.push(itemTemp);
    });
    setCidChapters(temp);
    return {subItems: temp};
  }

  function parseCalculators() {
    let temp = [];
    calcList.forEach((item) => {
      let subTemp = [];
      item.children.forEach((item) => {
        subTemp.push({
          key: `${item.ref}`,
          text: `${item.title}`,
          isActive: false,
          onClick: () => {
            history.push(`/app/calculators/${item.ref}`);
            trackEvent("Abriu calculadora", {
              calculadora: `${item.title}`,
            });
          },
        });
      });
      const itemTemp = {
        key: `${item.title}`,
        text: `${item.title}`,
        isActive: false,
        subItems: subTemp,
      };
      temp.push(itemTemp);
    });
    setCalculatorSpecialties(temp);
    return temp;
  }

  const [fetchSpecialties, { loading, error, data }] = useLazyQuery(
    GET_SPECIALTIES,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [getSearchSuggestions, {data: search_suggestions}] = useLazyQuery(GET_SEARCH_SUGGESTIONS, {
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (data?.sidebar_specialties) {
      mapDrugSpecialties();
      mapRoutineSpecialties();
    }
  }, [data?.sidebar_specialties]);

  const [myBB, setMyBB] = useState({drug: [], routines: []})


  // ------------ FUNCOES MEU BLACKBOOK ---------
  async function handleGetMeuBlackbook() {
    const a = await getMyBlackbook()
    setMyBB(a)
  }

  async function addFavoriteByType(type, item_id, sub_item_id = undefined) {
    try {
      
      await addFavorite(type, item_id, sub_item_id)
      
      await handleGetMeuBlackbook()
      toast.success('Adicionado ao MEU BLACKBOOK')
    } catch (error) {
      console.log("Erro ao adicionar favoritos: ", error)
    }
  }
  
  async function removeFavoriteByType(type, item_id, sub_item_id = undefined) {
    try {
      await removeFavorite(type, item_id, sub_item_id)
      
      await handleGetMeuBlackbook()
      toast.success('Removido do MEU BLACKBOOK')
    } catch (error) {
      console.log("Erro ao remover favoritos: ", error)
    }
  }

  // ------ fim das funcoes meu blackbook --------

  useEffect(() => {
    parseCalculators();
    parseCid();
    getSearchSuggestions()
    handleGetMeuBlackbook()
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (
      (!drugsSpecialties.length || !routineSpecialties.length) &&
      getUserToken()
    )
      fetchSpecialties();
  }, [location.key]);

  function mapDrugSpecialties(externalData) {
    let dataToMap;
    externalData ? dataToMap = externalData : dataToMap = data;
    const drugSpecialties =
      dataToMap?.sidebar_specialties?.drug_specialties?.map((drugSpecialty) => {
        return {
          text: drugSpecialty.title,
          isActive: false,
          key: drugSpecialty._id,
          _trackID: "drug_specialty",
          subItems: drugSpecialty.therapeutic_groups?.map(
            (therapeuticGroup) => {
              return {
                text: therapeuticGroup.title,
                isActive: false,
                isBlocked: therapeuticGroup.is_blocked,
                key: `${drugSpecialty._id}/${therapeuticGroup._id}`,
                onClick: () => {
                  history.push(`/app/drugs/${therapeuticGroup._id}`, {
                    trackOrigin: "Menu lateral",
                    trackType: "Lista de especialidades",
                  });
                },
                _parentSpecialty: drugSpecialty.title,
                _trackID: "therapeutic_group",
              };
            }
          ),
        };
      });
    setDrugsSpecialties(drugSpecialties);
    return drugSpecialties;
  }

  function mapRoutineSpecialties(externalData) {
    let dataToMap;
    externalData ? dataToMap = externalData : dataToMap = data;
    const routineSpecialties =
      dataToMap?.sidebar_specialties?.routine_specialties?.map(
        (routineSpecialty) => {
          return {
            text: routineSpecialty.title,
            isActive: false,
            key: routineSpecialty._id,
            _trackID: "routine_specialty",
            subItems: routineSpecialty.routines?.map((routine) => {
              return {
                text: (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 14,
                        color: "#999",
                        lineHeight: "16px",
                      }}
                    >
                      {routine.title}
                    </span>
                    <span style={{ fontSize: 11, color: "#6F6F6F" }}>
                      {routine.legend}
                    </span>
                  </div>
                ),
                isActive: false,
                isBlocked: routine.is_blocked,
                key: `${routineSpecialty._id}/${routine._id}`,
                onClick: () => {
                  history.push(`/app/routines/${routine._id}`, {
                    trackOrigin: "Menu lateral",
                    trackType: "Lista de especialidades",
                  });
                },
                _parentSpecialty: routineSpecialty.title,
                _trackID: "routine",
                _routineTitle: `${routine.title} - ${routine.legend}`,
              };
            }),
          };
        }
      );
    setRoutineSpecialties(routineSpecialties);
    return routineSpecialties;
  }

  const items = [
    {
      text: "Home",
      isActive: false,
      icon: <BbHomeIcon />,
      key: "home",
      _trackID: "",
      onClick: () => {
        history.push("/app");
      },
      appearsInHome: true,
    },
    {
      text: "Meu Blackbook",
      icon: <MyBbkIcon />,
      isActive: false,
      key: "myblackbook",
      _trackID: "",
      onClick: () => {
        history.push("/myblackbook");
      },
    },
    {
      text: "Drogas",
      icon: <BbDrugIcon />,
      isActive: false,
      key: "drugs",
      _trackID: "sidebar",
      subItems: drugsSpecialties,
      appearsInHome: false,
    },
    {
      text: "Rotinas",
      icon: <BbRoutineIcon />,
      isActive: false,
      key: "routines",
      _trackID: "sidebar",
      subItems: routineSpecialties,
      appearsInHome: false,
    },
    {
      text: "Calculadoras",
      isActive: false,
      icon: <Calculator />,
      key: "calculators",
      _trackID: "sidebar",
      subItems: calculatorSpecialties,
      appearsInHome: false,
    },
    {
      text: "CID",
      isActive: false,
      icon: <CID />,
      key: "CID",
      _trackID: "sidebar",
      subItems: cidChapters,
      appearsInHome: false,
    },
    {
      text: "Conta",
      isActive: false,
      icon: <BbAvatarIcon />,
      key: "account",
      _trackID: "",
      onClick: () => {
        history.push("/home");
      },
      appearsInHome: true,
    },
    {
      text: "Fale Conosco",
      isActive: false,
      icon: <BbChatIcon />,
      key: "chat",
      _trackID: "",
      appearsInHome: true,
      onClick: () => {
        window.open(
          "https://wa.me/5531996887607?text=Olá,%20preciso%20de%20ajuda.",
          "_blank"
        );
      },
    },
    // {
    //   text: "Sobre",
    //   isActive: false,
    //   icon: <BbSmartphoneIcon />,
    //   key: "about",
    //   _trackID: "",
    //   onClick: () => {
    //     history.push("/app");
    //   },
    //   appearsInHome: true,
    // },
    {
      text: "Sair",
      isActive: false,
      icon: <BbExitIcon />,
      key: "exit",
      _trackID: "",
      onClick: () => handleLogoutUser(),
      appearsInHome: true,
    },
  ];

  async function handleLogoutUser() {
    await trackEvent("Clicou em Sair");
    handleClearUserData();
    handleRemoveIndicationCouponOfLocalStorage();
    history.go("/");
  }

  function closeSidebarItems(e) {
    // lastButtonClicked &&
    //   trackApi.sendEvent(`Navegação ${lastButtonClicked.text} - Fechou`, {
    //     web: true,
    //   });
    setisSubsideBarOpen([false]);
    setHightlightedItem([]);
  }

  async function handleClickTemp(item, hierarchy) {
    item.text !== "Sair" &&
      hierarchy <= 0 &&
      trackEvent(`Clicou em ${item.text}`);
    if (item.onClick) {
      setisSubsideBarOpen([false]);
      setHightlightedItem([]);
      return item.onClick();
    }
    let subSidebarItemsAux = [...subSidebarItems];

    subSidebarItemsAux[hierarchy] = item.subItems;

    setSubSidebarItems([...subSidebarItemsAux]);

    let isSubsideBarOpenAux = isSubsideBarOpen.slice(0, hierarchy);
    isSubsideBarOpenAux[hierarchy] = true;
    setisSubsideBarOpen([...isSubsideBarOpenAux]);

    let hightlightedItemAux = hightlightedItem.slice(0, hierarchy);
    hightlightedItemAux[hierarchy] = item.key;
    setHightlightedItem([...hightlightedItemAux]);
  }

  async function handleClickItemOnHome({ base, item_key = null }) {
    setIsSidebarOpen(true);
    const specialty = items.find((item) => item.key === base);

    let sidebarArray = [specialty.subItems];
    let isSubsideBarOpenAux = [true];
    let hightlightedItemAux = [specialty.key];

    if (item_key) {
      const sub_item = specialty.subItems?.find(
        (item) => item.key === item_key
      );
      sidebarArray[1] = sub_item.subItems;

      isSubsideBarOpenAux[1] = true;
      hightlightedItemAux[1] = sub_item.key;
    }

    setSubSidebarItems([...sidebarArray]);
    setisSubsideBarOpen([...isSubsideBarOpenAux]);
    setHightlightedItem([...hightlightedItemAux]);
  }

  const context = {
    data,
    drugsSpecialties,
    routineSpecialties,
    isSidebarOpen,
    setIsSidebarOpen,
    subSidebarItems,
    isSubsideBarOpen,
    hightlightedItem,
    closeSidebarItems,
    handleClickTemp,
    items,
    handleClickItemOnHome,
    fetchSpecialties,
    parseCid,
    parseCalculators,
    mapRoutineSpecialties,
    mapDrugSpecialties,
    search_suggestions,
    myBB,
    removeFavoriteByType,
    addFavoriteByType,
    handleGetMeuBlackbook
  };

  return (
    <>
      <SidebarSpecialtiesContext.Provider value={context}>
        {children}
      </SidebarSpecialtiesContext.Provider>
    </>
  );
};

export function useSidebar() {
  const context = useContext(SidebarSpecialtiesContext);
  return context;
}
