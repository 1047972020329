import React, { useEffect, useRef, useState } from 'react';
import { SuggestionContainer } from './style';
import { useSidebar } from '../../../context/useSidebar';
import { SearchIcon } from '../../../assets/icons/SearchIcon';
import { Alarm } from '../../../assets/icons/Alarm';
import { BackArrow } from '../../../assets/icons/BackArrow';
import calculatorList from '../../../context/utils/calcListForSearch.json';
import cidSubCap from '../../../context/utils/CIDJson/subcat.json';

function SuggestionButton({
  item,
  onClick,
  onClickAddToInput,
  hasAddToInputBtn,
}) {
  function handleIcon() {
    const option = {
      default: () => <SearchIcon size="18px" />,
      recently: () => <Alarm size="18px" />,
    };

    return option?.[item?.icon]?.() || option.default();
  }
  return (
    <li onClick={onClick}>
      {handleIcon()}
      <span>{item.title}</span>

      <div
        onClick={(e) => {
          e.stopPropagation();
          onClickAddToInput();
        }}
        className="addToInput__container"
      >
        {!!hasAddToInputBtn && (
          <BackArrow color="#d6d6d6" style={{ transform: 'rotate(45deg)' }} />
        )}
      </div>
    </li>
  );
}

export function SuggestionComponent({
  inputValue,
  setInputValue,
  onClickSuggestion,
  setIsSearchComponentOpen,
}) {
  const { search_suggestions } = useSidebar();

  const [suggestions, setSuggestions] = useState([]);

  function handleFilterSuggestions(inputValue) {
    let suggestedItems = search_suggestions?.searchSuggestions || null;
    setSuggestions([]);

    let term = inputValue
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .trim()
      .toLowerCase();

    let recentlySearched = localStorage.getItem('@recently_seached');
    recentlySearched = !!recentlySearched ? JSON.parse(recentlySearched) : null;

    let suggestedItemsAux = [];

    if (!!recentlySearched?.length) {
      const recentlyList = recentlySearched.filter((e) =>
        e
          ?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .trim()
          .toLowerCase()
          .startsWith(term)
      );
      const a =
        recentlyList.length > 15 ? recentlyList.slice(0, 20) : recentlyList;

      let parsed = a.map((e) => {
        return { title: e, _id: e, icon: 'recently' };
      });
      suggestedItemsAux.push(...parsed);
    }

    if (!!term) {
      suggestedItemsAux.unshift({
        title: inputValue,
        _id: 'input-value',
        removeAddToInput: true,
      });

      if (!!suggestedItems?.length) {
        const suggestList = suggestedItems
          .filter((e) =>
            e.title
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .trim()
              .toLowerCase()
              .startsWith(term)
          )
          .sort((a, b) => b.rank_points - a.rank_points);
        const suggestSliced =
          suggestList.length > 100 ? suggestList?.slice(0, 100) : suggestList;

        suggestedItemsAux.push(...suggestSliced);
      }
      if (!!cidSubCap?.length) {
        const suggestCIDList = cidSubCap
          .filter(
            (e) =>
              e.DESCRICAO.normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase()
                .startsWith(term) ||
              e.SUBCAT.normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase()
                .startsWith(term)
          )
          .sort((a, b) => b?.SUBCAT - a?.SUBCAT);

        const suggestSliced =
          suggestCIDList.length > 100
            ? suggestCIDList.slice(0, 100)
            : suggestCIDList;

        let parsed = suggestSliced.map((e) => {
          return { title: e.SUBCAT + ' - ' + e.DESCRICAO, _id: e.SUBCAT };
        });

        suggestedItemsAux.push(...parsed);
      }
      if (!!calculatorList?.length) {
        const suggestCalcList = calculatorList
          .filter((e) =>
            e.title
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .trim()
              .toLowerCase()
              .startsWith(term)
          )
          .sort((a, b) => b?.title - a?.title);

        const suggestSliced =
          suggestCalcList.length > 100
            ? suggestCalcList.slice(0, 100)
            : suggestCalcList;

        let parsed = suggestSliced.map((e) => {
          return { title: e.title, _id: e.ref };
        });

        suggestedItemsAux.push(...parsed);
      }
    }

    //  sendEven('Busca - digitou um termo', {
    //       'Termo digitado': term2,
    //       'Sugestão 1': suggestSliced[0]?.title || null,
    //       'Sugestão 2': suggestSliced[1]?.title || null,
    //       'Sugestão 3': suggestSliced[2]?.title || null,
    //     });

    setSuggestions(suggestedItemsAux);
  }

  useEffect(() => {
    handleFilterSuggestions(inputValue);
  }, [inputValue]);

  const newRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (newRef.current && !newRef.current.contains(event.target)) {
        setIsSearchComponentOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [newRef]);

  return (
    <SuggestionContainer>
      <ul ref={newRef}>
        {suggestions
          ?.slice?.(0, 20)
          ?.map?.((suggestion) => (
            <SuggestionButton
              key={suggestion._id}
              item={suggestion}
              onClick={() => onClickSuggestion(suggestion.title)}
              onClickAddToInput={() => setInputValue(suggestion.title)}
              hasAddToInputBtn={!suggestion.removeAddToInput}
            />
          )) || null}
      </ul>
    </SuggestionContainer>
  );
}
