import { gql, useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { CardComponent } from '../../../../components/atoms/cardComponent';
import { TitleComponent } from '../../../../components/atoms/titleComponent';

import { RegisterOrganism } from '../../../../components/organisms/registerOrganism';
import { SignUpStageContext } from '../../../../context/useSignUpStage';
import { getIndicationCoupon } from '../../../../services/cache/indicationCoupon';
import { useIsDesktopOrTabletScreen } from '../../../../services/hooks/useIsDesktopOrTabletScreen';
import { useTrack } from '../../../../services/hooks/useTrack';
import { getAnonymousToken, handleSetLoginToken } from '../../../../services/tokenService';
import { UseTermsStyle } from './style';
import { UseTermsTextBox } from './UseTermsTextBox';

const SET_USER_REGISTER = gql`
  mutation Mutation(
    $email: String
    $password: String
    $name: String
    $phoneNumber: String
    $countryDial: String
    $graduationYear: String
    $actuationAreaId: String
    $anonymousToken: String
    $register_coupon_code: String
    $specialties: [String]
  ) {
    register(
      email: $email
      password: $password
      name: $name
      phone_number: $phoneNumber
      country_dial: $countryDial
      graduation_year: $graduationYear
      actuation_area_id: $actuationAreaId
      anonymousToken: $anonymousToken
      register_coupon_code: $register_coupon_code
      specialties: $specialties
    ) {
      token
      refreshToken
      _id
    }
  }
`;

export function UseTerms() {
  const { goForward } = useContext(SignUpStageContext);
  const {trackEvent} = useTrack();
  const [isLoading, setIsLoading] = useState(false);
  const isDesktop = useIsDesktopOrTabletScreen()

  const[handleRegister] = useMutation(SET_USER_REGISTER)

  const acceptTerms = async () => {
    setIsLoading(true);
    trackEvent("Clicou em Concordo",{'device': isDesktop ? 'large':'small', 'site':'app.blackbook'})
    try {
      const couponStored = getIndicationCoupon()
      const userData = {
        email: window.sessionStorage.getItem('email'),
        password: window.sessionStorage.getItem('password'),
        name: window.sessionStorage.getItem('name'),
        phoneNumber: window.sessionStorage
          .getItem('phone')
          .replace(/[^\d]/g, ''),
        countryDial: '+' + window.sessionStorage.getItem('country_dial'),
        graduationYear: window.sessionStorage.getItem('graduationYear'),
        actuationAreaId: window.sessionStorage.getItem('actuation_area_id'),
        anonymousToken: getAnonymousToken(),
        register_coupon_code: couponStored || '',
        specialties: JSON.parse(window.sessionStorage.getItem('specialties')) || [],
      };

      const response = await handleRegister({variables:userData});
      await handleSetLoginToken(response?.data?.register?.token);

      toast.success('Cadastro completado com sucesso!');
      goForward();
    } catch (error) {
      console.log(error);
      toast.error('Ocorreu um erro! Tente novamente mais tarde.');
    }
    setIsLoading(false);
  };

  return (
    <RegisterOrganism
      title="Crie sua conta"
      onFinish={() => acceptTerms()}
      buttonProps={{
        disabled: isLoading,
        text: 'Li e concordo',
        type: 'submit',
        className: 'fullWidth',
        loading: isLoading,
      }}
    >
      <UseTermsTextBox />
    </RegisterOrganism>
  );
}
