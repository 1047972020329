import { SubAccordionComponent } from '../../blackbook-components/atoms/accordion';
import { ContentSidebarComponent } from '../../blackbook-components/molecules/content-sidebar';
import { ContentSliderComponent } from '../../blackbook-components/molecules/content-slider';
import { DrugCardComponent } from '../../blackbook-components/molecules/drug-card';
import { DrugResumeComponent } from '../../blackbook-components/molecules/drug-resume';
import { PopupContentComponent } from '../../blackbook-components/molecules/popup-content';
import { WebAppTemplate } from '../../components/templates/WebAppTemplate';
import { ContentWrapper } from './style';
import { trackApi } from '../../services/clients/track';
import { GET_THERAPEUTIC_GROUP } from './query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { brandsTemplates } from '../../components/templates/DrugsSections/brandsTemplates';
import { DosageTemplates } from '../../components/templates/DrugsSections/dosageTemplates';
import { specialTemplates } from '../../components/templates/DrugsSections/specialTemplates';
import { defaultTemplateSection } from '../../components/templates/DrugsSections/defaultTemplateSection';
import { contraIndicationsTemplates } from '../../components/templates/DrugsSections/contraIndicationsTemplates';
import { usePopup } from '../../context/usePopup';
import { AccordionTable } from '../../components/atoms/AccordionTable';
import { IconPopupComponent } from '../../blackbook-components/molecules/icon-popup';
import { BBLoadingGif } from '../../blackbook-components/quarks/blackbook-loading';
import { useTrack } from '../../services/hooks/useTrack';
import _ from 'lodash';
import { useMyBlackbook } from '../../services/hooks/useMyBlackbook';
import { FavoriteModal } from '../../components/molecules/FavoriteModal';
import { toast } from 'react-toastify';
import { BreadCrumbDrugsComponent } from '../../blackbook-components/atoms/breadcrumbDrugs';

export function WebAppDrugs() {
  const { therapeutic_group_id, active_principle_id, section_id } = useParams();
  const location = useLocation()

  const [scrolledCard, setScrolledCard] = useState('');
  const [highlitedIndex, setHighlitedIndex] = useState(0);

  const [slideTo, setSlideTo] = useState(0);

  const { popup, setPopup } = usePopup();
  const { trackEvent } = useTrack();
  const history = useHistory();

  const {getFavoritesByType, addFavorite, removeFavorite} = useMyBlackbook();
  const [favorites, setFavorites] = useState(undefined);
  const [itemToRemove, setItemToRemove] = useState(null);

  async function handleFavorites() {
    const {tg_ap} = await getFavoritesByType("tg_ap", therapeutic_group_id);
    setFavorites(tg_ap);
  }

  async function removeFavoriteByType(item) {
    if (item?.type === 'therapeutic_group') {
      const isRemove = await removeFavorite('therapeutic_group', item?.tgId, undefined);
      if(isRemove) {
        setFavorites({
          ...favorites,
          isTGFavorite: false
        });
        toast.success('Removido do MEU BLACKBOOK');
      }
      setItemToRemove(null);
      return;
    }
    
    if (item?.type === 'active_principle') {
      const isRemove = await removeFavorite('active_principles', item?.apId, item?.tgId);
      if(isRemove) {
        setFavorites({
          ...favorites,
          activePrinciplesFavorites: favorites?.activePrinciplesFavorites.filter(a => a !== item?.apId)
        });
        toast.success('Removido do MEU BLACKBOOK');
      }
      setItemToRemove(null);
      return;
    }
  }

  async function addFavoriteByType(item) {
    if (item?.type === 'therapeutic_group') {
      const isAdd = await addFavorite('therapeutic_group', item?.tgId, undefined);
      
      if(isAdd) {
        setFavorites({
          activePrinciplesFavorites: favorites?.activePrinciplesFavorites || [],
          therapeuticGroupId: item?.tgId,
          isTGFavorite: true
        });
        toast.success('Adicionado ao MEU BLACKBOOK');
      }
      return;
    }
    
    if (item?.type === 'active_principle') {
      const isAdd = await addFavorite('active_principles', item?.tgId, item?.apId);
      
      if(isAdd) {
        setFavorites({
          therapeuticGroupId: item?.tgId,
          isTGFavorite: favorites?.isTGFavorite || false,
          activePrinciplesFavorites: [...favorites?.activePrinciplesFavorites || [], item?.apId]
        });
        toast.success('Adicionado ao MEU BLACKBOOK');
      }
      return;
    }
  }

  useEffect(() => {
    handleFavorites();
  }, [location.key]);

  function handleLinksNavigate(e) {
    e?.preventDefault();

    if (e.target.tagName === "A") {
      const href = e.target.getAttribute("href");
      if(href.includes('http')) { 
        window.open(href, "_blank");
      } else {
        history.push(href.replaceAll("/null", ""), {
          trackOrigin: "Drogas",
          trackType: "Link",
        }); 
      }
    }
  }

  function setIconPopup(popup) {
    let linkString = ""
    let parsedPopup = {...popup}
    if(!popup.link){
      setPopup(popup);
      return
    }
    if(popup.link.type === "R"){
      linkString = `/app/routines/${popup.link.routine_id}${popup.link.tab_id ? "/" + popup.link.tab_id : ""}${popup.link.card_id ? "/" + popup.link.card_id : ""}`
    } else {
      linkString = `/app/drugs/${popup.link.therapeutic_group_id}${popup.link.activePrinciple_id? "/" + popup.link.activePrinciple_id : ""}${popup.link.dosage_id ? "/" + popup.link.dosage_id : ""}`
    }
    parsedPopup.link = linkString
    setPopup(parsedPopup);
  }

  useEffect(() => {
    const setProps = setTimeout(() => {
      const props = {
        'Grupo Terapêutico': breadCrumb.children,
        'Nome do Princípio Ativo': scrolledCard,
        web: true,
      };
      trackEvent('Visualizou o Princípio Ativo', props);
    }, 2000);
    return () => clearTimeout(setProps);
  }, [scrolledCard]);

  const { loading, error, data } = useQuery(GET_THERAPEUTIC_GROUP, {
    variables: { therapeuticGroupId: therapeutic_group_id },
    onCompleted:(drug)=>{
      if(location.state.trackOrigin === "Banner"){
        trackEvent("Clicou no banner", {"Destino":drug.therapeutic_group_populated.title, "Origem":"Banner","Tipo da origem":"Banner","Tipo do destino":"Drogas","Link id":location.state.trackLink})
      }
      trackEvent("Acessou Drogas", {
        "Tipo da origem": location?.state?.trackType,
        "Origem": location?.state?.trackOrigin,
        "Grupo terapêutico": drug?.therapeutic_group_populated?.title,
        "Resolução": `(${window.screen?.width * window.devicePixelRatio} X ${window.screen?.height * window.devicePixelRatio})`
      });
    },
  });


  const indexOfAPOnParam = active_principle_id
    ? data?.therapeutic_group_populated?.active_principles?.findIndex(
        (ap) => ap._id === active_principle_id
      )
    : null;

  useEffect(() => {
    const storageIndex = sessionStorage.getItem(location.key)
    if(storageIndex) {
      handleClickContentSidebarItem({index: Number(storageIndex)});
    } else {
      handleClickContentSidebarItem({index: indexOfAPOnParam || 0});
    }


    if (active_principle_id && section_id) {
      const apElement = document.getElementById(active_principle_id);
      if (apElement) {
        setTimeout(() => {
        const contentElement = apElement?.querySelector(`.drugCard__content`);

          const sectionElement = document.getElementById(section_id);
          const a = sectionElement.parentNode.closest('.sub_accordion')
          const parentElement = sectionElement.closest(`#${sectionElement.getAttribute('data-parent')}`);

          contentElement?.scrollTo({
            top: parentElement?.offsetTop
              + a?.offsetTop - 40
              + sectionElement?.offsetTop - 40
              - 60
              , 
            behavior: "smooth"
          });
        }, 1000);
      }
    }
  }, [indexOfAPOnParam, location.key]);

  const breadCrumb = {
    children: data?.therapeutic_group_populated?.title,
    tg_id: data?.therapeutic_group_populated?._id,
    isFavorited: favorites?.isTGFavorite || false,
    isPaid: data?.therapeutic_group_populated?.is_blocked,
    addFavorite: (item) => addFavoriteByType(item),
    removeFavorite: (item) => setItemToRemove(item),
  };

  const sidebarItems = {
    items: data?.therapeutic_group_populated?.active_principles?.map(
      (item, i) => {
        if (i === highlitedIndex) {
          return {
            text: item.title,
            isActive: true,
          };
        }
        return { text: item.title };
      }
    ),
  };

  function checkIfIsOpen(e) {
    if(!section_id) return false
    const a = e?.find(i => i._id === section_id)
    return a ? true : false
  }

  const accordions = {
    resume: ({ resume }) => {
      if (
        !resume.brands.length &&
        !resume.icons.length &&
        !resume.observations &&
        !resume.presentations.length &&
        !resume.prices.length &&
        !resume.whiteboard
      ) {
        return null;
      }

      return (
        <DrugResumeComponent
          id="resume"
          brands={resume.brands.map((brand) => {
            return {
              title: brand.title,
              laboratoryTitle: brand.laboratory,
            };
          })}
          presentations={resume.presentations.map(
            (presentation) => presentation.pharmaceutical_form.title
          )}
          prices={resume.prices.map((price) => {
            return {
              title: price.description,
              value: price.range ? (
                <span className="dolarSign">
                  <span className="active_dolarSign">
                    {'$'.repeat(price.range)}
                  </span>
                  {'$'.repeat(7 - price.range)}
                </span>
              ) : (
                `R$ ${price.value}`
              ),
            };
          })}
          icons={resume.icons}
          whiteboard={resume.whiteboard}
          observations={resume.observations}
          iconFunction={setIconPopup}
        />
      );
    },
    brands: ({ brands_and_presentations }) => {
      const brandsArray = [
        { type: 'reference', title: 'Referência', template: 'default' },
        { type: 'generic', title: 'Genéricos', template: 'default' },
        { type: 'similar', title: 'Similares', template: 'default' },
        { type: 'biological', title: 'Biológicos', template: 'default' },
        { type: 'specific', title: 'Específicos', template: 'default' },
        {
          type: 'herbal_medicines',
          title: 'Fitoterápicos',
          template: 'default',
        },
        { type: 'others', title: 'Outros', template: 'default' },
        { type: 'essential', title: 'Essenciais', template: 'essentials' },
        { type: 'state_owned', title: 'Estatais', template: 'default' },
        { type: 'manipulated', title: 'Manipulados', template: 'default' },
        { type: 'imported', title: 'Importados', template: 'default' },
        { type: 'doubt', title: 'Inconsistentes', template: 'doubt' },
        {
          type: 'discontinued',
          title: 'Descontinuados',
          template: 'discontinued',
        },
      ]
        .map(({ type, title, template }) =>
          brandsTemplates[template]({
            brands: brands_and_presentations?.[type], 
            title,
            isOpen: checkIfIsOpen(brands_and_presentations?.[type]),
            iconPopup: setIconPopup
          })
        )
        .filter((e) => !!e);

      if (brandsArray?.length > 0) return brandsArray;
      return null;
    },
    dosage: ({ dosage_and_indications }) => {
      const dosageArray = [
        { type: 'adult', title: 'Adultos' },
        { type: 'child', title: 'Crianças' },
        { type: 'old_man', title: 'Idosos' },
        { type: 'teenager', title: 'Adolescentes' },
      ]
        .map((category) =>
          DosageTemplates({
            dosage: dosage_and_indications?.[category.type],
            title: category.title,
            isOpen: checkIfIsOpen(dosage_and_indications?.[category.type])
          })
        )
        .filter((e) => !!e);
      if (dosageArray?.length > 0) return dosageArray;
      return null;
    },
    special: ({ special_situation }) => {
      const adjustmentsArray = (special_situation?.renal_adjustments?.observation
        .length > 0 ||
        special_situation?.hepatic_adjustments?.observation.length > 0) && (
        <SubAccordionComponent title="Ajustes" type="yellow" isOpen={true}>
          {specialTemplates.default(
            special_situation.renal_adjustments,
            'Ajustes na insuficiência renal',
            'grandchild'
          )}
          {specialTemplates.default(
            special_situation.hepatic_adjustments,
            'Ajustes na insuficiência hepática',
            'grandchild'
          )}
        </SubAccordionComponent>
      );

      const specialSituationsArray = [
        { type: 'breast_feeding', title: 'Amamentação', template: 'icon' },
        { type: 'gestation', title: 'Gestação', template: 'icon' },
        {
          type: 'intoxication_antidote',
          title: 'Intoxicação e antídoto',
          template: 'default',
        },
        {
          type: 'drug_interactions',
          title: 'Interações medicamentosas',
          template: 'default',
        },
      ]
        .map(({ type, title, template }) =>
          specialTemplates[template](special_situation?.[type], title, setIconPopup)
        )
        .filter((e) => !!e);

      if (!!adjustmentsArray?.length || !!specialSituationsArray?.length) {
        return [adjustmentsArray, ...specialSituationsArray];
      }
      return null;
    },
    adverseEffects: ({ adverse_effects }, type) => {
      const adverseEffectsArray = [
        { type: 'adverse_reactions', title: 'Reações adversas' },
        { type: 'toxicity', title: 'Toxicidade' },
        { type: 'by_administration', title: 'Por via de administração' },
        {
          type: 'dependence_tolerance_abstinence',
          title: 'Dependência, tolerância, abstinência',
        },
      ]
        .map((category) =>
          defaultTemplateSection(
            adverse_effects?.[category.type],
            category.title,
            type
          )
        )
        .filter((e) => !!e);
      if (adverseEffectsArray?.length > 0) return adverseEffectsArray;
      return null;
    },

    useAndSafety: ({ use_and_security }, type) => {
      const useAndSafetyArray = [
        {
          type: 'preparation_administration',
          title: 'Preparo e administração',
        },
        { type: 'patient_advice', title: 'Aconselhamentos ao paciente' },
        { type: 'monitoring', title: 'Monitoramento' },
        { type: 'warnings_precautions', title: 'Advertências e precauções' },
      ]
        .map((category) =>
          defaultTemplateSection(
            use_and_security?.[category.type],
            category.title,
            type
          )
        )
        .filter((e) => !!e);

      if (useAndSafetyArray?.length > 0) return useAndSafetyArray;
      return null;
    },

    contraIndications: ({ contra_indications }) =>
      contraIndicationsTemplates.default(contra_indications),
  };

  const [isAccordionOpen, setIsAccordionOpen] = useState({
    resume: true,
    brand: true,
    dosage: true,
    special_situation: true,
    adverse_effects: true,
    use_and_safety: true,
    contraindications: false,
  });

  const items = data?.therapeutic_group_populated?.active_principles?.map(
    (item) => (
      <DrugCardComponent
        slideTo={slideTo}
        setSlideTo={setSlideTo}
        key={item._id}
        id={item._id}
        title={item.title}
        tg_title={data?.therapeutic_group_populated?.title}
        tg_id={data?.therapeutic_group_populated?._id}
        isPaid={data.therapeutic_group_populated.is_blocked}
        isFavorited={favorites?.activePrinciplesFavorites?.includes(item?._id?.toString())}
        addFavorite={addFavoriteByType}
        removeFavorite={setItemToRemove}
        sections={[
          {
            title: 'Resumo',
            type: 'resume',
            id: 'resume',
            content: accordions['resume'](item),
            isOpen: isAccordionOpen.resume,
            onOpenCloseCallback: () =>
              setIsAccordionOpen({
                ...isAccordionOpen,
                resume: !isAccordionOpen.resume,
              }),
          },

          {
            title: 'Marcas e apresentações',
            type: 'blue',
            id: 'brands',
            content: accordions['brands'](item),
            isOpen: isAccordionOpen.brand,
            onOpenCloseCallback: () =>
              setIsAccordionOpen({
                ...isAccordionOpen,
                brand: !isAccordionOpen.brand,
              }),
          },
          {
            title: 'Doses e indicações',
            type: 'green',
            id: 'dosage',
            content: accordions['dosage'](item),
            isOpen: isAccordionOpen.dosage,
            onOpenCloseCallback: () =>
              setIsAccordionOpen({
                ...isAccordionOpen,
                dosage: !isAccordionOpen.dosage,
              }),
          },
          {
            title: 'Situações especiais',
            type: 'yellow',
            id: 'special',
            content: accordions['special'](item),
            isOpen: isAccordionOpen.special_situation,
            onOpenCloseCallback: () =>
              setIsAccordionOpen({
                ...isAccordionOpen,
                special_situation: !isAccordionOpen.special_situation,
              }),
          },
          {
            title: 'Contraindicações',
            type: 'orange',
            id: 'contra_indications',
            content: accordions['contraIndications'](item),
            isOpen: isAccordionOpen.contraindications,
            onOpenCloseCallback: () =>
              setIsAccordionOpen({
                ...isAccordionOpen,
                contraindications: !isAccordionOpen.contraindications,
              }),
          },
          {
            title: 'Efeitos colaterais',
            type: 'red',
            id: 'adverse_effects',
            content: accordions['adverseEffects'](item, 'red'),
            isOpen: isAccordionOpen.adverse_effects,
            onOpenCloseCallback: () =>
              setIsAccordionOpen({
                ...isAccordionOpen,
                adverse_effects: !isAccordionOpen.adverse_effects,
              }),
          },
          {
            title: 'Uso e segurança',
            type: 'indigo',
            id: 'use_and_safety',
            content: accordions['useAndSafety'](item, 'indigo'),
            isOpen: isAccordionOpen.use_and_safety,
            onOpenCloseCallback: () =>
              setIsAccordionOpen({
                ...isAccordionOpen,
                use_and_safety: !isAccordionOpen.use_and_safety,
              }),
          },
        ]}
      />
    )
  );

  useEffect(() => {
    setIsAccordionOpen({
      resume: true,
      brand: true,
      dosage: true,
      special_situation: true,
      adverse_effects: true,
      use_and_safety: true,
      contraindications: false,
    });
    setHighlitedIndex(0);
  }, [therapeutic_group_id]);

  function sendActivePrincipleEvent(e) {
    setHighlitedIndex(e.activeIndex);
    setScrolledCard(
      data.therapeutic_group_populated.active_principles[e.activeIndex].title
    );
    debouceSetStorageScrollHandler(e, location.key)
  }

  const debouceSetStorageScrollHandler = useCallback(
    _.debounce( (e, location_key) => {
      sessionStorage.setItem(location_key, e.activeIndex)
    }, 500),
    []
  );

  function closeSidebar() {
    // console.log('Clique');
  }

  const debouceFunction = useCallback(
    _.debounce( (itemIndex) => {
      setSlideTo(`${itemIndex}`);
      setTimeout(() => {
        document.querySelector('[data-current-active-principle="true"]')?.focus()
      }, 300)
    }, 100),
    []
  );

  function handleKeyPress(e) {    
    if(e.key === 'ArrowLeft' || e.keyCode === 37) {
      const drugCards = [].slice.call(document.querySelectorAll(`.drugCard__content`));
      const itemIndex = drugCards.findIndex(e => e.dataset.currentActivePrinciple === 'true')
      debouceFunction(itemIndex - 1)
    }

    if(e.key === 'ArrowRight' || e.keyCode === 39) {
      const drugCards = [].slice.call(document.querySelectorAll(`.drugCard__content`));
      const itemIndex = drugCards.findIndex(e => e.dataset.currentActivePrinciple === 'true')
      debouceFunction(itemIndex + 1)
    }
  }

  useEffect(() => {
    
    document.addEventListener('keydown', (e) => handleKeyPress(e))

    return document.removeEventListener('keydown', () => null)
  }, [])

  function handleClickContentSidebarItem(item) {
    if (typeof item?.index === 'number') {
      setSlideTo(`${item.index}`);
      setTimeout(() => {
        document.querySelector('[data-current-active-principle="true"]')?.focus()
      }, 300)
    }
  }
  return (
    <WebAppTemplate>
      {
        popup != null && (
        <PopupContentComponent isOpen={popup != null} popupTable={true}>
          {
            popup.__typename !== "IIcon" ?
            <AccordionTable extra={popup} popup={true} onClose={()=> setPopup(null)}/>
            :
            <IconPopupComponent onClose={()=> setPopup(null)} popup={popup}/>
          }
        </PopupContentComponent>
        )
      }
      {loading || error || favorites === undefined ? (
        <BBLoadingGif />
        ) : (
          <ContentWrapper onClick={handleLinksNavigate}>
            <BreadCrumbDrugsComponent {...breadCrumb} className="breadCrumb" />
            <ContentSidebarComponent
              items={sidebarItems.items}
              className="contentSidebar"
              onClick={handleClickContentSidebarItem}
            />
            <div className="content">
              <ContentSliderComponent
                items={items}
                slideFunction={sendActivePrincipleEvent}
                touchFunction={closeSidebar}
              />
            </div>
          </ContentWrapper>
        )}
      {
        itemToRemove &&
        <FavoriteModal 
          cancelOnPress={() => setItemToRemove(null)}
          successOnPress={() => removeFavoriteByType(itemToRemove)}
          title='REMOVER DO MEU BLACKBOOK ?'
          titleContent={itemToRemove?.title}
          legendContent={itemToRemove?.legend}
          isLinkedContentAlert={itemToRemove?.type === 'routine'}
          isLoadingButton={false}
        />
      }
    </WebAppTemplate>
  );
}
